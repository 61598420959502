import { handleActions, createAction } from 'redux-actions';
import {
  UUID,
  Email,
  JWT,
  LanguageTag,
} from '../interfaces';

export interface SessionState {
  jwt?: JWT
  email?: Email
  language?: LanguageTag
  userId?: UUID
  isLoading: boolean
  failed: boolean
  logoutUrl: string
  // TODO: to be removed when AccessControlCrate will be provided
  userRole?: 'USER' | 'OWNER'
  modules?: any[]
  sessionExpiration: string
  organizationName: string
}

const initialState: SessionState = {
  userRole: 'USER',
  language: 'en-US',
  logoutUrl: '/api/auth/v1/logout',
  isLoading: true,
  failed: false,
  sessionExpiration: '',
  organizationName: '',
};

export const getSessionInitialized = createAction(
  '@platform/query/me/getSession/init',
);

export const getSessionSucceeded = createAction<any, any>(
  '@platform/query/me/getSession/success',
  ({
    token,
    email,
    // eslint-disable-next-line camelcase
    default_module_id,
    // eslint-disable-next-line camelcase
    session_expiration,
    userId,
    logoutUrl,
    organizationName,
  }) => ({
    jwt: token,
    email,
    defaultModuleId: default_module_id,
    sessionExpiration: session_expiration,
    userId,
    logoutUrl,
    organizationName,
  }),
);

export const getOwnDetailsSucceeded = createAction<any, any>(
  '@platform/query/me/get/success',
  payload => payload,
);

export const getModulesSucceeded = createAction<any, any>(
  '@platform/query/modules/get/success',
  payload => payload,
);

export const appInitializationFailed = createAction(
  '@platform/query/me/getSession/failed',
);

export default handleActions<SessionState, any>(
  {
    [getSessionInitialized as any]: state => ({
      ...state,
      isLoading: true,
      failed: false,
    }),
    [getSessionSucceeded as any]: (state, { payload }) => ({
      ...state,
      ...payload,
      isLoading: false,
      failed: false,
    }),
    [getOwnDetailsSucceeded as any]: (state, { payload }) => ({
      ...state,
      userRole: payload.attributes.role,
      language: payload.attributes.language,
    }),
    [getModulesSucceeded as any]: (state, { payload }) => ({
      ...state,
      modules: payload,
    }),
    [appInitializationFailed as any]: state => ({
      ...state,
      failed: true,
      isLoading: false,
    }),
    '@platform/command/me/changeLanguage/success': (state, { payload }) => ({
      ...state,
      language: payload.language,
    }),
  },
  initialState,
);
